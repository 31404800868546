<template>
  <div class="container mt-5">
    <article-modal v-if="getIsShowingModal" />

    <div class="row">
      <div class="col-md-8 mx-auto folder-card">
        <div class="d-flex justify-content-between align-items-center">

          <template v-if="isDeleting">
            <p class="mb-0 fw-bold text-danger">Delete the folder?</p>
            <p class="mb-0 text-danger">
              <span @click="deleteCurrentFolder" class="me-2" style="cursor:pointer;">Yes</span>
              <span @click="isDeleting = false" style="cursor:pointer;">No</span>
            </p>
          </template>

          <template v-else>
            <div class="d-flex align-items-center">
              <p class="mb-0 me-3 back-icon" @click="goBack">
                <chevron-left-icon />
              </p>
              <p class="mb-0 fw-bold">Folder: {{ getCurrentFolderData.name }}</p>
            </div>
            <div class="d-flex align-items-center">
              <p v-if="!isDisabled" class="mb-0 download-report me-3" @click="downloadArticles">
                <download-icon size="20" />
              </p>
              <p class="mb-0 delete-folder" @click="isDeleting = true;">
                <trash2-icon size="20" />
              </p>
            </div>
          </template>

        </div>
      </div>
    </div>

    <div v-if="getIsLoadingFolders">
      <div class="text-center">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div v-for="article in getCurrentFolderData.articles" :key="article.id">
        <article-card :article="article" />
      </div>

      <div v-if="getCurrentFolderData.articles.length <= 0">
        <p>This folder doesn't contain any articles.</p>
      </div>
    </div>

  </div>
</template>

<script>
import ArticleModal from "@/components/articles/article-modal/ArticleModal";
import ArticleCard from "@/components/articles/articles-card/ArticleCard";
import Spinner from "@/components/Spinner";
import { ChevronLeftIcon, DownloadIcon, Trash2Icon } from "vue-feather-icons";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Folder",
  components: { ArticleModal, Spinner, ArticleCard, Trash2Icon, ChevronLeftIcon, DownloadIcon },
  computed: {
    ...mapGetters('folders', ['getCurrentFolderData', 'getIsLoadingFolders', 'getIsDownloadReport']),
    ...mapGetters('articles', ['getIsShowingModal']),
    ...mapGetters('accounts', ['getCurrentUser']),

    isDisabled() {
      return this.getCurrentUser.downloads === false;
    }
  },

  data() {
    return {
      isDeleting: false
    }
  },

  methods: {
    ...mapActions('folders', ['fetchFolderData', 'deleteFolder', 'resetArticleFolderData', 'downloadArticlesFromFolder']),

    deleteCurrentFolder() {
      this.deleteFolder(this.getCurrentFolderData.id);
    },

    downloadArticles() {
      if (!this.getIsDownloadReport) {
        this.downloadArticlesFromFolder(this.getCurrentFolderData.id);
      }
    },

    goBack() {
      this.$router.push({ name: "AllFolders" })
      this.resetArticleFolderData();
    }

  },

  created() {
    this.fetchFolderData(this.$route.params.id);
  }

}
</script>

<style lang="scss" scoped>
.folder-card {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: 1.5rem 2.1rem;
}

.delete-folder {
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    color: #999999;
  }
}

.back-icon {
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    color: #999999;
  }
}

.download-report {
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    color: #999999;
  }
}
.disabled {
  pointer-events: none !important;
  opacity: 0.6;
}
</style>